.v-card {
    border-radius: 6px;

    .v-card__title {
        font-size: 21px;
        padding-top: 7px;
        padding-bottom: 8px;
        color: #2e2e2e;
    }
    .v-card__text {
        color: #940b0b;
        margin-bottom: 16px;
    }
    .v-card__subtitle {
        color: #4e4e4e;
    }
    .v-card-gradient {
        height: 100px;
        background: linear-gradient(
            180deg,
            rgba(197, 253, 224, 0.373) 0%,
            rgb(255, 255, 255) 100%
        );
        border-radius: 5px;
    }
}
.v-card__actions {
    padding-top: 15px;
    .display-2 {
        font-size: 18px !important;
    }
}

// .v-card--testimony
//   .v-card__text
//     color: #999 !important

//   .display-2
//     font-size: 18px !important

//   .body-2
//     font-weight: 500
//     font-size: 12px !important

//   .v-avatar
//     left: calc(50% - 50px)

// .ct-square:before
//   float: none
