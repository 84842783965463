#app-bar {
    background-color: #363636;
    color: #dedde0;
    .v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        color: #dedde0;
    }
}
.v-navigation-drawer {
    background-color: #363636 !important;
    color: #dedde0 !important;

    .v-list {
        background-color: #363636 !important;
        border-radius: 0 !important;
        box-shadow: none;
    }
    .v-list-item {
        color: #dedde0 !important;
    }
    .v-list--dense {
        .v-list-item {
            min-height: 35px !important;
        }
    }
    // .v-list-item__title {
    // }
    .v-subheader {
        font-size: 0.8rem !important;
        background-color: #363636 !important;
        color: #888887 !important;
    }
    .v-icon {
        color: #29e4ac !important;
    }
    .v-list-item__icon:first-child {
        margin-right: 15px !important;
    }
    .v-avatar {
        .v-icon {
            color: #ebe2e4 !important;
        }
    }
    .korisnik-info {
        background-color: #ffffff;
    }
}
// -----------------------
// .v-toolbar__content,
// .v-toolbar__extension
//   padding: 0px 15px 0 31px

// .v-toolbar__content
//   .v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab),
//   .v-btn.v-size--large:not(.v-btn--icon):not(.v-btn--fab)
//     margin-bottom: 5px
//     padding: 10px 15px !important

//   .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
//     background-color: #fff

//     .v-icon
//       color: #999

// .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
//   background-color: #fff
//   margin-right: 17px
//   margin-bottom: 2px

// .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover
//   background-color: #fff

// .v-toolbar__content
//   height: 75px

// .v-toolbar__content .v-btn--flat
//   .v-icon
//     margin-right: 3px

// .theme--light.v-label
//   color: rgba(0, 0, 0, 0.3)

// .v-menu__content .v-list--nav
//   padding: .3125rem 0
//   border-radius: 4px
//   .v-list-item
//     padding: 10px 20px
//     margin: 0 .3125rem
//     margin-bottom: 0px !important
//     min-height: 40px
//     border-radius: 2px
//     .v-list-item__title
//       font-weight: 400
//       font-size: 13px

// .v-navigation-drawer .v-icon.v-icon
//   font-size: 24px
