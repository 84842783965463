#login-forma {
    .v-card {
        background-color: #f7f6f4;
    }
    .v-card__title {
        background-color: #f7f6f4;
        padding-bottom: 20px;
    }
    .v-card__subtitle {
        background-color: #f7f6f4;
        padding-bottom: 30px;
    }

    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
        > .v-input__control
        > .v-input__slot
        fieldset {
        color: #313c55 !important;
        background-color: #ffffff;
    }
    .v-text-field--outlined.v-input--has-state fieldset,
    .v-text-field--outlined.v-input--is-focused fieldset {
        color: #313c55 !important;
        background-color: #ffffff;
    }
    .error--text {
        color: #0b4f58 !important;
    }
    .v-icon {
        color: #353333;
    }
}
