.routerLink {
  text-decoration: none;
  text-transform: uppercase;
}

.routerLink:hover {
  text-decoration: none;
  color: #7d8edd;
}

.v-list-item__title {
  font-size: 0.95rem !important;
}

#login-forma .v-card {
  background-color: #f7f6f4;
}
#login-forma .v-card__title {
  background-color: #f7f6f4;
  padding-bottom: 20px;
}
#login-forma .v-card__subtitle {
  background-color: #f7f6f4;
  padding-bottom: 30px;
}
#login-forma .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: #313c55 !important;
  background-color: #ffffff;
}
#login-forma .v-text-field--outlined.v-input--has-state fieldset,
#login-forma .v-text-field--outlined.v-input--is-focused fieldset {
  color: #313c55 !important;
  background-color: #ffffff;
}
#login-forma .error--text {
  color: #0b4f58 !important;
}
#login-forma .v-icon {
  color: #353333;
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover {
  color: #ccc8cc;
}

#app-bar {
  background-color: #363636;
  color: #dedde0;
}
#app-bar .v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  color: #dedde0;
}

.v-navigation-drawer {
  background-color: #363636 !important;
  color: #dedde0 !important;
}
.v-navigation-drawer .v-list {
  background-color: #363636 !important;
  border-radius: 0 !important;
  box-shadow: none;
}
.v-navigation-drawer .v-list-item {
  color: #dedde0 !important;
}
.v-navigation-drawer .v-list--dense .v-list-item {
  min-height: 35px !important;
}
.v-navigation-drawer .v-subheader {
  font-size: 0.8rem !important;
  background-color: #363636 !important;
  color: #888887 !important;
}
.v-navigation-drawer .v-icon {
  color: #29e4ac !important;
}
.v-navigation-drawer .v-list-item__icon:first-child {
  margin-right: 15px !important;
}
.v-navigation-drawer .v-avatar .v-icon {
  color: #ebe2e4 !important;
}
.v-navigation-drawer .korisnik-info {
  background-color: #ffffff;
}

.v-data-table td {
  font-weight: 300;
  padding: 12px 8px;
}

.v-data-table table thead tr th {
  font-weight: 300;
  font-size: 17px;
  padding: 0px 8px;
}

.v-data-table table tbody tr td .v-btn {
  margin-right: 0px !important;
}

.v-data-table .v-data-table-header__sort-badge {
  font-size: 10px;
}

.v-data-table.theme--dark tr th {
  color: #fff !important;
}

.theme--light .v-data-table table thead tr th {
  color: #1a1818;
}

.theme--light.v-footer {
  padding: 20px 0 20px 4px;
  position: fixed;
  background-color: #555555 !important;
  color: #ffffff !important;
}

.v-card {
  border-radius: 6px;
}
.v-card .v-card__title {
  font-size: 21px;
  padding-top: 7px;
  padding-bottom: 8px;
  color: #2e2e2e;
}
.v-card .v-card__text {
  color: #940b0b;
  margin-bottom: 16px;
}
.v-card .v-card__subtitle {
  color: #4e4e4e;
}
.v-card .v-card-gradient {
  height: 100px;
  background: linear-gradient(180deg, rgba(197, 253, 224, 0.373) 0%, rgb(255, 255, 255) 100%);
  border-radius: 5px;
}

.v-card__actions {
  padding-top: 15px;
}
.v-card__actions .display-2 {
  font-size: 18px !important;
}

.poruke {
  color: #ffffff;
}
.poruke .notification-title {
  text-align: center;
  font-size: 1.5rem;
}
.poruke .notification-content {
  text-align: center;
}
.poruke.success {
  background: #112090 !important;
  border-left-color: #bf7139 !important;
}
.poruke.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}
.poruke.error {
  background: #e54d42;
  border-left-color: #b82e24;
}

.porukeCard {
  margin-top: 2px;
}