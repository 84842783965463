.v-data-table {
}

.v-data-table td {
    font-weight: 300;
    padding: 12px 8px;
    // font-family: "Rubik" !important;
}

.v-data-table table thead tr th {
    font-weight: 300;
    font-size: 17px;
    padding: 0px 8px;
}

.v-data-table table tbody tr td .v-btn {
    margin-right: 0px !important;
}

.v-data-table .v-data-table-header__sort-badge {
    font-size: 10px;
}

.v-data-table.theme--dark tr th {
    color: #fff !important;
}

.theme--light .v-data-table table thead tr th {
    color: #1a1818;
}
