.routerLink {
    text-decoration: none;

    text-transform: uppercase;
}
.routerLink:hover {
    text-decoration: none;
    color: #7d8edd;
}

// /* Change cursor when mousing over clickable layer */
// .leaflet-clickable {
//     cursor: crosshair !important;
// }
// /* Change cursor when over entire map */
// .leaflet-container {
//     cursor: help !important;
// }
