// style of the notification itself
.poruke {
    // margin: 0 5px 5px;

    // font-size: 12px;
    // color: #ffffff;

    // // default (blue)
    //background: #44a4fc;
    //border-left: 5px solid #187fe7;
    // style for title line
    color: #ffffff;
    .notification-title {
        text-align: center;
        font-size: 1.5rem;
    }

    // style for content
    .notification-content {
        text-align: center;
    }

    //Stilovi su u vuetify plugin
    &.success {
        background: #112090 !important;
        border-left-color: #bf7139 !important;
    }

    &.warn {
        background: #ffb648;
        border-left-color: #f48a06;
    }

    &.error {
        background: #e54d42;
        border-left-color: #b82e24;
    }
}
.porukeCard {
    margin-top: 2px;
}
